<template>
  <div class="portfolio" data-page>
    <DynamicContainer transition="slideUp" :parent="$document.querySelector('#routerView')" v-for="(project, pId) in portfolio" :key="pId" :duration="pId%4 * 100 + 500">
        <div class="projectCard" data-card="borderless" :style="$isDesktop ? `background-image: url(${project.image});` : null">
            <a v-if="project.url" :href="project.url" class="url" target="_blank" rel="noreferrer">
                <img src="/img/ic_external.svg" alt="" >
            </a>
            <div class="image" :style="`background-image: url(${project.image});`"></div>
            <div class="details">
                <div class="name">{{project.name}}</div>
                <small class="description" v-html="project.description"></small>
            </div>
        </div>
    </DynamicContainer>
  </div>
</template>

<script>
import portfolio from "@/JSONData/portfolio"
export default {
    data: () => ({
        portfolio: portfolio.sort((a,b) => (a.annee < b.annee ? 1 : -1))
    })
}
</script>

<style lang="stylus" scoped>
.projectCard
    overflow hidden
    display flex
    flex-direction column
    margin-bottom 1rem
    color var(--color-text)
    position relative
    .image
        background-repeat no-repeat
    .url
        position absolute
        height 3rem
        width 3rem
        border-radius 50%
        background rgba(0,0,0,.5)
        display flex
        align-items center
        justify-content center
        padding .5rem
        box-sizing border-box
        backdrop-filter blur(2px)
    .name
    .description
        display block
        text-align center
    .name
        padding-top 1rem
        font-size 1.2rem
        font-weight bold
    .description
        padding 0 .5rem .5rem .5rem
        height 3.5rem
.isMobile
    .portfolio
        .projectCard
            .url
                top 10rem
                right 1rem
            .image
                background-size cover
                background-position center center
                height 14rem
                box-shadow inset 0 -2px 4px rgba(0,0,0,.2)
.isDesktop
    .portfolio
        display flex
        flex-wrap wrap
        justify-content space-evenly
        .projectCard
            width calc(300pt - 1.5rem)
            margin .5rem
            transition .3s
            .url
                top 1rem
                right 1rem
                transform scale(0)
                transition .4s
                transition-delay .3s
            .image
                background-position center center
                height 14rem
                transition .4s
                transition-delay .2s
                background-size 120%
            .details
                position absolute
                left 0
                right 0
                bottom 0
                max-height 3rem
                display flex
                flex-direction column
                justify-content center
                transition .5s
                opacity 0
                background rgba(0,0,0,.5)
                backdrop-filter blur(2px)
                text-shadow 0 0 3px black
                color white
            .name
                height 1.5rem
                max-height 1.5rem
            .description
                max-height 0
                overflow hidden
                opacity 0
                transition .3s
            &:hover
                transform translateY(-.5rem)
                box-shadow 0 9px 18px rgba(0,0,0,.4)
                .image
                    background-size 100%
                .details
                    opacity 1
                    max-height 6.5rem
                .description
                    max-height 3.5rem
                    opacity 1
                .url
                    transform scale(1)
</style>