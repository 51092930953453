<template>
  <header data-page id="mainMenu">
    <router-link to="/" id="homeLink">
      <img id="logo" src="/img/logo.svg" alt="">
    </router-link>
    <img id="punchline" src="/img/punchline.svg" alt="">
    <div style="flex: 1"></div>

    <router-link class="menuItem" :to="menu.route" v-for="(menu, index) in menus" :key="index">
      {{menu.name}}
    </router-link>
    <router-link to="/about" id="avatar">
      <img src="/img/avatar.png" alt="">
    </router-link>
  </header>
</template>

<script>
import menus from '@/JSONData/menu'
export default {
  data: () => ({
    menus
  })
}
</script>

<style lang="stylus" scoped>
#mainMenu
  position fixed
  top 0
  left 0
  right 0
  z-index 9
  display flex
  align-items center
  transition .5s
  border-bottom-left-radius 150% 40%
  border-bottom-right-radius 150% 40%
a
  color white
  text-decoration none
  padding .5rem 1rem
  font-weight bold
  opacity .5
  transition .2s
  position relative
  &::after
    content ''
    width .2rem
    height .1rem
    display block
    background transparent
    border-radius .5rem
    position absolute
    bottom 0
    left calc(50% - .1rem)
    transition .3s
    transform rotate(-360deg)
  &.router-link-active
    opacity 1
    &::after
      width 1.5rem
      height .2rem
      background white
      left calc(50% - .75rem)
      opacity .5
      transform rotate(0deg)
  &:hover
    opacity 1
#homeLink::after
  display none
#logo
  height 3rem
  filter contrast(0) brightness(300%)
  opacity .5
#punchline
  margin-left 1rem
  height 1rem
  filter contrast(0) brightness(300%)
  opacity 0
  transition .2s
  transform translateX(8rem)
#avatar
  background rgba(0,0,0,.2)
  display block
  height 3rem
  width 3rem
  border-radius 50%
  animation avatar-enter .5s linear forwards
  transform scale(0)
  animation-delay 1s
  margin-left 1rem
  padding 0
  opacity 1
  &::after
    display none
  img
    display block
    height 3rem
    width 3rem
    border-radius 50%
    box-shadow 0 0 0 4px rgba(255,255,255,.3)
</style>

<style lang="stylus">
.scrolled
  #mainMenu
    background var(--color-header)
    backdrop-filter blur(4px)
    margin-left -2rem
    margin-right -2rem
    box-shadow 0 0 .75rem 1px rgba(0,0,0,.25)
    a
      color var(--color-text)
      opacity .9
      &.router-link-active
        &::after
          opacity .9
          background #6BD
    #logo
    #punchline
      filter contrast(100%) brightness(100%)
      opacity 1
    #punchline
      transition-delay .5s
      transform translateX(0)
      opacity .5
@media (prefers-color-scheme: dark)
  .scrolled
    #mainMenu
      #logo
      #punchline
        filter contrast(40%) brightness(120%)
</style>