<template>
    <div id="wrapper">
        <slot name="prefix"></slot>
        <span :key="word" id="spinner" :style="`animation-duration: ${length}ms; text-align: ${textAlign}`" :class="{last: key == list.length - 1}">
            {{word}}
        </span>
        <slot name="suffix"></slot>
    </div>
</template>

<script>
export default {
    props: {
        words: {
            default : () => {
                return ['creative technology']
            }
        },
        textAlign: {
            default: 'center'
        },
        duration: {
            default: 1.5
        },
        loopAfter: {
            default: 0
        }
    },
    data() {
        return {
            word: '',
            key: 0,
            list: this.words,
            length: this.duration * 1000
        }
    },
    methods: {
        loop() {
            this.list.forEach((word, index) => {
                setTimeout(()=>{
                    this.word = word
                    this.key = index
                    if(index == this.list.length - 1 && this.loopAfter) {
                        setTimeout(this.loop, this.loopAfter*1000)
                    }
                }, this.length * index)
            })
        }
    },
    mounted() {
        this.loop()
    }
}
</script>

<style lang="stylus" scoped>
#wrapper
    display flex
    font-size 2.5rem
    margin .5rem auto
#spinner
    margin auto
    animation 1.5s spinUp linear
    transform-origin center center
.isMobile #wrapper
    white-space nowrap
    font-size 1.5rem
#spinner.last
    animation 1.5s spinUpLast linear

@keyframes spinUp {
    from, to, 5%, 95% {
        opacity 0
    }
    from {
        transform translateY(90%)
    }
    to {
        transform translateY(-90%)
    }

    15%, 85% {
        transform translate(0)
        opacity 1
    }
}

@keyframes spinUpLast {
    from, 5% {
        opacity 0
    }
    from {
        transform translateY(.5rem)
    }
    15%, 85%, to {
        opacity 1
        transform translate(0)
    }
}

</style>