<template>
  <div style="flex: 1"></div>
  <footer data-page>
      <section id="links">
          <b>Liens</b>
          <router-link to="/about">
            <img src="/img/avatar.png" alt="">
            À propos...
          </router-link>
          <a href="https://www.linkedin.com/in/meyergre/">
            <img src="/img/ic_linkedin.svg" alt="">
            LinkedIn
          </a>
      </section>

      <section id="legal">
            <b>Mentions légales</b>
            <small>Site édité par Grégory Meyer en qualité d'auto-entrepreneur</small>
            <small>10 Wolfweg 68500 Issenheim</small>
            <small>N° Siret 813 210 119 00026</small>
            <small>TVA non applicable - article 293B du CGI</small>
      </section>

      <section id="other">
          <b>Documents</b>
          <a download href="/cvg.pdf">
            <img src="/img/ic_pdf.svg" alt="">
            Conditions Générales de Vente
          </a>
          <a download href="/CV.pdf">
            <img src="/img/ic_pdf.svg" alt="">
            Curriculum Vitae
          </a>
      </section>

      <section id="bottom">
        Le site et ses données sont hébergés par MVPS LTD., Medousis 26, Katsiantionis & Charalambous court 2, flat 202, Larnaca, 6059, Cyprus (Europe).
        <br/>
        Aucune donnée personnelle n'est collectée à votre insu.
      </section>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="stylus" scoped>
footer
    margin-top 4rem
    background rgba(11,11,11,.75)
    backdrop-filter blur(2px)
    color #eee
    position sticky
    top 100%
    section
        display flex
        flex-direction column
        padding .5rem
        a
            color #ccc
            text-decoration none
            text-align inherit
            display flex
            align-items center
            img
                height 1.2rem
                width 1.2rem
                margin 0 .5rem
                border-radius 3px
        b
            font-size .6rem
            opacity .5
            padding 0 .75rem
        &#bottom
            opacity .5
            font-size .8rem
            line-height 100%
            text-align center
            margin-top 1rem
            padding-top 1rem
            border-top 1px solid rgba(255,255,255,.1)
        &#legal
            display inline-flex
            flex-direction column
            small
                font-size .85rem
                padding 0 .75rem
        &>b
            line-height 2rem
.isMobile
    footer
        flex-direction column
        padding-bottom 6rem
        section
            a
                padding 1rem .5rem
.isDesktop
    footer
        flex-direction row
        align-items flex-start
        flex-wrap wrap
        justify-content space-between
        #bottom
            width 100%
        section
            box-sizing border-box
            width calc(100% / 3)
            display inline-flex
            vertical-align top
            a
                padding .25rem
        #legal
            align-items center
            text-align center
        #other
            text-align right
            align-items flex-end
            a
                flex-direction row-reverse
</style>