import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import DynamicContainer from '@/components/DynamicContainer'

const isMobileByUA = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
const isMobileByTouch = ('ontouchstart' in document.documentElement)
const isMobileByScreenOrientation = window.innerWidth < window.innerHeight && window.innerWidth < 1000

const app = createApp(App)
    .use(router)

app.component('DynamicContainer', DynamicContainer)
app.config.globalProperties.$isMobile = isMobileByScreenOrientation && (isMobileByUA || isMobileByTouch)
app.config.globalProperties.$isDesktop = !app.config.globalProperties.$isMobile
app.config.globalProperties.$document = document

app.mount('#app')
