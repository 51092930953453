<template>
  <div class="about" data-page>
    <img src="/img/photograph.jpg" alt="" data-card="borderless" style="width: 200px">
    <div style="max-width: 640pt">
      <h1>Grégory Meyer</h1>
      <small>Développeur / Graphiste auto-entrepreneur</small>
      <p>Passionné par les nouvelles technologies depuis le plus jeune âge, j'ai commencé très tôt à me familiariser avec les outils de dessin et de développement informatiques.</p>
      <p>Autodidacte, j'ai appris au fil des années les bases du HTML, CSS, Javascript, PHP et Python, avant de me lancer dans la découverte de Java, puis de Kotlin dans le cadre du développement Android, et dans une moindre mesure de Swift dans le cadre du développement iOS.</p>
      <p>J'ai concrétisé mon expérience par l'obtention d'un BTS en Informatique de Gestion en 2010.</p>
      <p>Régulièrement sollicité par mon entourage, et afin de pouvoir explorer de nouveaux projets dans des environnements variés, je me suis inscrit en tant qu'auto-entrepreneur et j'ai ouvert orym.net pour présenter mon activité en 2015.</p>
      <p>Basé en Alsace, je propose mes services dans toute la France, et même au-delà. Travaillant principalement à distance pour toutes les réalisations techniques, les contraintes géographiques s'évaporent et me permettent d'intervenir partout, depuis partout.</p>
      <p>En revanche, pour les services sur site ou à domicile, je limite les déplacement à un rayon raisonnable depuis mon domicile.</p>
      <p>Je suis à l'écoute de toute soumission de projet: web, logiciel d'entreprise, application mobile, design de logo ou de documents, assistance à domicile, etc... n'hésitez pas à <router-link to="/contact">me contacter</router-link>.</p>
      <br/>
      <a download href="/CV.pdf" id="monCV">
        <img src="/img/ic_pdf.svg" alt="">
        Télécharger mon CV
      </a>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.about
  color white
  padding-bottom 5rem
  h1
    opacity .75
  small
    opacity .5
  a
    color rgba(255,255,255,.6)
    font-weight bold
  p
    text-align justify

  #monCV
    display flex
    align-items center
    text-decoration none
    img
      height 2rem
      width 2rem
      margin-right .5rem

.isMobile
  .about
    margin-top -4rem
.isDesktop
  .about
    min-height calc(100vh - 22rem)
    display flex
    align-items flex-start
    &>img
      margin-right 2rem
</style>