<template>
  <div class="contact" data-page>
    <div :data-card="$isDesktop || null" id="wrapper">
      <form method="POST" id="form" @submit.prevent="onSubmit" :data-card="$isMobile || null">
        <h2>Me contacter</h2>
        <DynamicContainer :parent="$document.querySelector('#routerView')" transition="slideDown">
        <div class="message warning">
          <b>À l'attention des demandeurs d'emploi ou d'alternance</b>:<br/> je suis au regret de vous annoncer qu'aucune demande d'emploi, de stage ou d'alternance ne trouvera de réponse favorable, du fait de mon statut d'auto-entrepreneur cumulé à celui de salarié. Merci pour votre compréhension.
        </div>
        </DynamicContainer>
        <label>
          <input type="text" name="name" required placeholder=" ">
          <div>Votre nom</div>
        </label>
        <label>
          <input type="email" name="email" required  placeholder=" ">
          <div>Votre e-mail</div>
        </label>
        <label>
          <input name="phone" type="phone"  placeholder=" ">
          <div>Votre téléphone <small>(facultatif)</small></div>
        </label>
        <label>
          <textarea name="message" required style="min-height: 12rem" placeholder=" "></textarea>
          <div>Votre message</div>
        </label>
        <div style="display: flex; align-items: center">
          <button type="submit" :disabled="sending"> Envoyer </button>
          <div v-if="!sending && success !== undefined" class="message" :class="{'success': success === true, 'error': success === false}">
            <span v-if="success === true">
              Votre message a bien été envoyé !
            </span>
            <span v-if="success === false">
              Une erreur est survenue, veuillez réessayer dans quelques instants
            </span>
          </div>
        </div>
      </form>
      <div id="infos" :data-card="$isMobile || null">
        <h2>Infos pratiques</h2>
        <b>Grégory Meyer</b>
        <small>10 Wolfweg, 68500 Issenheim</small>
        <small>Tél.: <a title="Appeler" style="text-decoration-color: #27A2; color: #27A" href="tel:0618447785">06 18 44 77 85</a> (laisser un message, <b><u>pas de SMS</u></b>)</small>
        <small>N° Siret: 813 210 119 00026</small>
        <b>Horaires</b>
        <small style="color: #D44">Locaux d'habitation, non ouverts au public</small>
        <small>
          Déplacement <u style="text-decoration-color: rgba(0,0,0,.1)">sur rendez-vous uniquement</u>,
          <br/>
          Du Lundi au Vendredi après 18h
          <br/>
          Samedi, Dimanche et jours fériés
        </small>
        <iframe data-v-772a872a="" id="gmap" data-ui-card="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.558909642448!2d7.256551316274186!3d47.906222079205655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4791793e87950879%3A0x31614f409c064052!2sWolfweg%2C%2068500%20Issenheim!5e0!3m2!1sfr!2sfr!4v1580732293444!5m2!1sfr!2sfr" frameborder="0" allowfullscreen="allowfullscreen" style="border: 0px; flex: 1 1 0%;"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    success: undefined,
    sending: false
  }),
  methods: {
    onSubmit(e) {
      this.sending = true
      const body = {}
      e.target.forEach(field => {
        if(field.name) {
          body[field.name] = field.value
        }
      })
      fetch('/sendmail', {
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(body),
      })
      .then(() => {
        this.success = true
        e.target.reset()
        this.sending = false
      }).catch(() => {
        this.success = false
        this.sending = false
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
::placeholder
  font-family "gothamrnd", sans-serif
  font-size .85rem

h2
  margin-top 0

textarea
button
  font-family "gothamrnd", sans-serif
  &:disabled
    opacity .3
    filter grayscale(100%)
    cursor not-allowed
#form
  label
    display flex
    flex-direction column
    margin-bottom 1rem
    padding-top .5rem
    position relative
    div
      position absolute
      top 1rem
      left .5rem
      font-size .85rem
      opacity .6
      transition .2s
    input:not(:placeholder-shown)+div
    textarea:not(:placeholder-shown)+div
      top -.75rem
      opacity 1
      color #27A
  input
  textarea
    padding .5rem
    border 1px solid rgba(0,0,0,.3)
    border-radius .25rem
    outline none
    transition .1s
    &:focus
      box-shadow 0 0 0 1px #27A
    &:required + div::after
      content " *"
      color #d44

  button
    padding .75rem 1.5rem
    display flex
    align-items center
    background #27A
    border 1px solid rgba(0,0,0,.1)
    border-radius .5rem
    box-shadow 0 3px 6px rgba(0,0,0,.25)
    color white
    font-size 1rem
    cursor pointer
    transition .5s
    &:hover
      transform scale(1.1)
      box-shadow 0 6px 14px rgba(0,0,0,.15)


  .message
    background gold
    padding .5rem
    border-radius .5rem
    border 1px solid rgba(0,0,0,.1)
    font-size .85rem
    &.warning
      margin-bottom 2rem
    &.success
      background #4B95
      margin 0 1rem
    &.error
      background #D445
      margin 0 1rem
@media (prefers-color-scheme: dark)
  .message
    color var(--color-white)
#wrapper
  display flex
  align-items flex-start

#infos
  min-width 200pt
  display flex
  flex-direction column
  line-height 2rem

#gmap
  border-radius .5rem
  width 100%
  box-shadow 0 0 0 1px rgba(0,0,0,.15)
  min-height 200px
  margin-top 1rem

.isDesktop
  .contact
    #wrapper
      padding 5rem
      #form
        flex 1.5
        margin-right 5rem
      #infos
        flex 1

.isMobile
  .contact
    #wrapper
      flex-direction column
</style>