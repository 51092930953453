<template>
  <MobileMenu v-if="$isMobile"/>
  <MainMenu v-else />
  <div id="routerView" :class="{'isMobile': $isMobile, 'isDesktop': $isDesktop, 'waved': $isDesktop}" ref="routerView">
    <router-link to="/about" id="avatar" v-if="$isMobile && $route.path != '/about'">
      <picture>
        <source type="image/webp" srcset="/img/avatar.webp">
        <source type="image/png" srcset="/img/avatar.png">
        <img src="/img/avatar.png" alt="À propos..." height="48" width="48">
      </picture>
    </router-link>
    <router-view />
    <div v-if="$isMobile" class="waved" style="height: 15rem; position: fixed; z-index: 1; top: calc(100% - 12rem); left: 0; right: 0;"></div>
    <Footer />
  </div>
</template>

<script>
import MobileMenu from '@/views/Menu/Mobile'
import MainMenu from '@/views/Menu/Main'
import Footer from '@/views/Footer'
export default {
  components: { MobileMenu, MainMenu, Footer },
  mounted() {
    document.dispatchEvent(new Event("x-app-rendered"))
    this.$refs.routerView.addEventListener('scroll', (e) => {
      if(e.target.scrollTop > 64) {
        document.body.classList.add('scrolled')
        } else {
          document.body.classList.remove('scrolled')
      }
    })
  },
  watch: {
    '$route.path': function() {
      this.$refs.routerView.scrollTo(0,0)
    }
  }
}
</script>
<style lang="stylus">
@font-face
  font-family "gothamrnd"
  src url("/fonts/GothamRounded-Light.otf")
  font-display swap
@font-face
  font-family "gothamrnd-memium"
  src url("/fonts/GothamRounded-Medium.otf")
  font-display swap

:root
  --color-white #fff
  --color-card #fff
  --color-input #fff
  --color-header rgba(255,255,255,.9)
  --color-text #666

@media (prefers-color-scheme: dark)
  :root
    --color-white #444
    --color-header #1a202cEE
    --color-text #eee
    --color-card #252f3f
    --color-input #2f3949

  #app  
    background-image linear-gradient(180deg,#1a202c66, #1a202c66), linear-gradient(30deg,rgba(120,20,128,.6),rgba(0,5,140,.7),rgba(0,90,140,.7),rgba(0,140,125,.7)), linear-gradient(180deg,rgba(0,0,30,.8),rgba(0,0,30,.6)), url(/img/background.jpg)!important
    background-image -webkit-linear-gradient(180deg,#1a202c66, #1a202c66), linear-gradient(30deg,rgba(120,20,128,.6),rgba(0,5,140,.7),rgba(0,90,140,.7),rgba(0,140,125,.7)), linear-gradient(180deg,rgba(0,0,30,.8),rgba(0,0,30,.6)), url(/img/background.webp)!important
  .waved
    background-image url(/img/anim/waves-dark.svg)!important
  input
  textarea
    background var(--color-input)
    color var(--color-text)


html
body
#app
  width 100%
  height 100%
  display flex
  flex-direction column
  margin 0
  padding 0
  overflow hidden
  background #282828
  font-size 12pt
  line-height 120%
  box-sizing border-box
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale

#app
  background-image linear-gradient(30deg,rgba(231,18,138,.533),rgba(35,54,193,.667),rgba(35,143,193,.667),rgba(35,193,175,.667)),linear-gradient(180deg,rgba(0,0,50,.75),rgba(0,0,50,.5)),url(/img/background.jpg)
  background-image -webkit-linear-gradient(30deg,rgba(231,18,138,.533),rgba(35,54,193,.667),rgba(35,143,193,.667),rgba(35,193,175,.667)),linear-gradient(180deg,rgba(0,0,50,.75),rgba(0,0,50,.5)),url(/img/background.webp)
  background-attachment fixed!important
  background-repeat no-repeat!important
  background-position top center!important
  background-size cover!important
  font-family "gothamrnd", sans-serif

#routerView
  flex 1
  overflow auto
  padding-top 10rem
  position relative
  box-sizing border-box
  scrollbar-width thin
  & > *
    z-index 2

[data-page]
  display block
  padding 1rem calc(50% - 600px)

[data-card]
  box-sizing border-box 
  border-radius 1rem
  box-shadow 0 5px 10px 1px rgba(0,0,0,.2)
  background var(--color-card)
  color var(--color-text)
  &:not([data-card="borderless"])
    padding 1.5rem

@media screen and (max-width 1240px)
  [data-page]
    padding 1rem 3rem

.isMobile
  [data-page]
    padding 1rem

#routerView.isMobile 
  font-size 14pt
  [data-card] + [data-card]
    margin-top 1.5rem

.isMobile #avatar
  position absolute
  top 1rem
  right 1rem
  background rgba(0,0,0,.2)
  display block
  height 3.5rem
  width 3.5rem
  border-radius 50%
  animation avatar-enter .5s linear forwards
  transform scale(0)
  animation-delay 1s
  img
    display block
    height 3.5rem
    width 3.5rem
    border-radius 50%
    box-shadow 0 0 0 4px rgba(255,255,255,.3)

@keyframes avatar-enter 
  from 
    box-shadow 0 0 0 4px rgba(255,255,255,.3)
    transform scale(0)
  95%
    transform scale(1.1)
  to
    box-shadow 0 0 10px 50px rgba(255,255,255,0)
    transform scale(1)
  
.waved
  background-image: url(/img/anim/waves.svg);
  background-position: bottom -2px left;
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;

.isMobile 
  .waved
    background-position bottom center
    pointer-events none
    opacity .6
    background-size 500vw
    background-position fixed
</style>
