import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Portfolio from '../views/Portfolio.vue'
import Contact from '../views/Contact.vue'
import RatatoskPolicy from '../views/Policies/Ratatosk.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '',
      description: ''
    }
  },
  {
    path: '/policies/android-apps/net.orym.ratatosk',
    name: 'RatatoskPolicy',
    component: RatatoskPolicy,
    meta: {
      title: 'Ratatosk - règles de confidentialité',
      description: ''
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'À propos',
      description: ''
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: 'Me contacter',
      description: ''
    }
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio,
    meta: {
      title: 'Portfolio',
      description: "Ils m'ont fait confiance pour gérer leurs projets de développement, création graphique... pourquoi pas vous ?"
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: {name: 'Home'}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const SITE_TITLE = ' | orym';
const DEFAULT_TITLE = 'Creative Technology';
const DEFAULT_DESCRIPTION = 'Depuis 2015, Grégory Meyer, Développeur et Graphiste Auto-entrepreneur, propose ses services de création personnalisée aux particuliers et professionnels en tant qu\'auto-entrepreneur dans le Haut-Rhin, en Alsace, et au-delà...!'
router.afterEach((to) => {
  document.title = (to.meta.title || DEFAULT_TITLE) + SITE_TITLE;
  document.querySelector('meta[name="og:title"]').setAttribute('content', (to.meta.title || DEFAULT_TITLE) + SITE_TITLE)
  document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description || DEFAULT_DESCRIPTION)
});

export default router
