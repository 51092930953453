<template>
<div id="mobile-menu-wrapper" :class="{open}" >
    <div id="backdrop" v-if="open" @click="() => { open = false; $forceUpdate() }"></div>
        <div id="roundedbar">
            <img src="/img/ic_menu.svg" alt="" id="menu-icon" @click="() => { open = true; $forceUpdate() }">
            <img id="menuBarDark" src="/img/menubar-dark.svg" alt="" class="center">
            <img id="menuBarLight" src="/img/menubar.svg" alt="" class="center">
        </div>
        <div id="mobile-menu" :class="{open}" :key="$route.path">
            <router-link to="/" style="text-align: center;">
                <img src="/img/logo.svg" alt="Logo" id="logo">
            </router-link>
            <img src="/img/punchline.svg" alt="Tagline" id="punchline">
            <div
                v-for="(entry, index) in menu"
                :key='index'
                class="entry"
                >
                <router-link
                    v-if="entry.route"
                    :class="`router-link ${entry.class||''}`"
                    :to="entry.route">
                    <span>{{entry.name}}</span>
                </router-link>
            </div>
            <div class="entry" id="actionButtons">
                <router-link to="/contact" style="background: #2072b8" id="contact_button">
                    <img src="/img/ic_mail.svg" alt="Mail" >
                </router-link>
                <a href="#" @click="() => { open = false; $forceUpdate() }" id="menu-close">
                    <img src="/img/ic_close.svg" alt="Close menu">
                </a>
                <a href="tel:0618447785" style="background: #22B820" id="phone_button">
                    <img src="/img/ic_phone.svg" alt="Phone" >
                </a>
            </div>
    </div>
</div>
</template>

<script>
import menu from '@/JSONData/menu-mobile'
export default {
    props: ['pinned'],
    data() {
        return {
            menu,
            open: false,
        }
    },
    watch: {
        '$route.path': function() {
            this.open = false;
        }
    }
}
</script>

<style lang="stylus">
#mobile-menu-wrapper
    position fixed
    left 0
    right 0
    bottom 0
    z-index 99
    background var(--color-card)
    display flex
    flex-direction column
    align-items center
    width 100vw
    transition .3s
    transform translateY(0%)
    &:not(.open)
        transform translateY(100%)
    &::before
        content ''
        transition .5s
        transition-timing-function ease-in-out
        z-index 9
        position fixed
        left 0
        right 0
        bottom 0
        height 400vw
        pointer-events none
        background rgba(0,0,0,.4)
        opacity 0
    &.open::before
        opacity 1
    & > #backdrop
        position fixed
        backdrop-filter blur(5px)
        left 0
        right 0
        bottom 0
        height 300vw

        z-index 9
#mobile-menu-wrapper #mobile-menu
    background var(--color-card)
    width 100%
    display flex
    flex-direction column
    align-items center
    overflow-y auto
    z-index 99
    padding 1rem auto
    &.open
        max-height 90vh
    & .router-link
        display block
        padding .75rem
        text-align center
        color var(--color-text)
        text-decoration none
        &.router-link-exact-active
            opacity .3
#mobile-menu-wrapper #roundedbar
    position relative
    width 100%
    margin-bottom -1px
    & > img.center
        position absolute
        bottom 0pt
        left 50%
        transform translate(-50%,0)
        height 68pt
        z-index 10
        box-sizing border-box
        pointer-events none
        z-index 10
#mobile-menu-wrapper #menu-icon
    position absolute
    z-index 9
    left calc(50% - 32pt)
    height 64pt
    width 64pt
    bottom 4pt
    transition .35s
    transition-delay .15s
    -webkit-tap-highlight-color transparent
    animation .5s menu-bounce ease-in-out 2

#mobile-menu-wrapper.open #menu-icon
    transition-timing-function ease-in-out
    transform translateY(35%) rotate(180deg)!important
    filter grayscale(25%) brightness(75%) contrast(128%)


#mobile-menu-wrapper #actionButtons > *
    height 24pt
    width  24pt
    margin .75rem auto 1.5rem auto
    transform scale(0)
    opacity 0
    transition .25s
    transition-delay .25s
    transition-timing-function ease-in-out
    border 1pt solid rgba(0,0,0,.035)
    border-radius 50%
    padding .5rem
    -webkit-tap-highlight-color: transparent;
#mobile-menu-wrapper.open #actionButtons > *
    opacity 1
    transform scale(1)

#mobile-menu-wrapper #logo
#mobile-menu-wrapper #punchline
    width 40%

#mobile-menu-wrapper #punchline
    margin-bottom 1rem

#actionButtons
    margin-top 1rem
    width 100vw
    display flex
    justify-content space-evenly
    align-items center

@media (prefers-color-scheme: dark)
    #menuBarLight
        display none

@media (prefers-color-scheme: light)
    #menuBarDark
        display none

@keyframes menu-bounce 
    0%
        transform translateY(0)
    35%
        transform translateY(-.5rem)
    80%
    100%
        transform translateY(0)
</style>