<template>
  <div class="home">
    <div id="spinner" data-page>
      <WordsSpinner :words="['Site web', 'Logo', 'Appli mobile', 'Cartes de visite', 'Modèles de docs', 'Une idée ?']" :loopAfter="10" />
      <br/>
      <small style="opacity: .5; text-align: center;">
        Chaque projet est unique.
        <br/>
        Surtout le votre.
      </small>
      <img src="/img/ic_scroll.svg" alt="" style="height: 3rem; width: 3rem; animation: scroll-indicator 1s ease-in-out infinite; margin: 3rem">
    </div>
    <div data-page >
      <div class="cards">
        <DynamicContainer transition="slideUp" :duration="400" :parent="$document.querySelector('#routerView')" data-card>
          <div class="cardBanner" style="background: linear-gradient(20deg,#236ddb,#3897ee,#4bbeff);">
              <img src="/img/illus/particulier.svg" alt="">
              <h2>Particuliers</h2>
          </div>
          <div class="cardContent">
            <h3 style="color: #3897ee">Offrez-vous un professionnel</h3>
            <ul>
              <li>
                <b>Dépannage, installation et formation à domicile</b>
                <small><br/>Diagnostic et dépannage de votre ordinateur, accompagnement pour son utilisation ou votre découverte d'internet, à tout âge.</small>
              </li>
              <br/>
              <li>
                <b>Concrétisation de vos projets</b>
                <small><br/>Un site internet n'est pas l'apanage des entreprises ! Donnez vie à vos projets.</small>
              </li>
            </ul>
          </div>
        </DynamicContainer>
        <DynamicContainer transition="slideUp" :duration="500" :parent="$document.querySelector('#routerView')" data-card>
          <div class="cardBanner" style="background: linear-gradient(20deg,#4c22b7,#b14f91,#ff7373);">
              <img src="/img/illus/entreprise.svg" alt="">
              <h2>Entreprises</h2>
          </div>
          <div class="cardContent">
            <h3 style="color: #b14f91">Boostez vos possibilités</h3>
            <ul>
              <li>
                <b>Ce n'est pas la taille qui compte...</b>
                <small><br/>Même les petites structures ont droit à une installation informatique de qualité et adaptée, pour travailler dans de bonnes conditions.</small>
              </li>
              <br/>
              <li>
                <b>Externalisez votre projet</b>
                <small><br/>Un projet ponctuel, un outil à développer... Faites appel à un développeur expérimenté et restez concentré sur vos activités.</small>
              </li>
            </ul>
          </div>
        </DynamicContainer>
      </div>
    </div>
    <DynamicContainer transition="slideUp" data-page :parent="$document.querySelector('#routerView')">
      <div class="services">
        <Services />
      </div>
    </DynamicContainer>
    <DynamicContainer transition="fade" data-page :parent="$document.querySelector('#routerView')">
      <div class="skills">
        <Skills />
      </div>
    </DynamicContainer>
  </div>
</template>

<script>
import WordsSpinner from '@/components/WordsSpinner'
import DynamicContainer from '@/components/DynamicContainer'
import Services from '@/views/Services'
import Skills from '@/views/Skills'
export default {
  name: 'Home',
  components: {
    WordsSpinner,
    DynamicContainer,
    Services,
    Skills
  }
}
</script>

<style lang="stylus" scoped>
.home
  color white
  .cardBanner
    border-radius .66rem
    width 100%
    height 8rem
    position relative
    &>img
      height 8.5rem
      margin-left 1rem
      margin-top -1rem
    &>h2
      position absolute
      top .5rem
      right 1rem
      margin 0
      color white
      font-weight normal
      text-transform uppercase
      font-family 'gothamrnd-memium'
  .cardContent
    padding 0 1rem
    ul
      padding-left 1rem
.isMobile
  .cards
    [data-card]
      margin 1rem
      .cardContent
        padding 0
        h3
          line-height 120%
  .services
    margin-top 1rem
    display flex
    flex-direction column
    align-items center
.isDesktop
  .cards
    display flex
    justify-content space-evenly
    [data-card]
      width 45%
  .services
    margin-top 2rem
    display flex
    flex-direction row
    align-items flex-start
    justify-content space-evenly
    flex-wrap wrap
#spinner
  display flex
  flex-direction column
  align-items center
  box-sizing border-box
  display flex
  align-items center
  justify-content center
.isDesktop
  #spinner
    min-height 80vh
    padding-bottom 8rem
.isMobile
  #spinner
    min-height 70vh
    padding-top 4rem
    padding-bottom 4rem
</style>
<style lang="stylus">
.isMobile
  #spinner
    font-size 2rem
    small
      line-height 1.5rem
      font-size 1rem
.isDesktop
  #spinner
    font-size 3rem
    small
      line-height 2rem
      font-size 1.25rem
@keyframes scroll-indicator
  from
    transform translateY(-1rem)
  from
  20%
    opacity 0
  50%
    opacity .5
  80%
  to
    opacity 0
  to
    transform translateY(1rem)
</style>