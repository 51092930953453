<template>
<div data-page>
    <div data-card>
        <h1>Règles de confidentialité</h1>
        <h2>Ratatosk pour Yggtorrent</h2>

        <h3>Données personnelles</h3>
        <p>Ratatosk ne collecte ni ne transmet aucune donnée personnelle vous concernant, ou concernant votre appareil.</p>
        <p>Votre identifiant utilisateur et votre mot de passe sont stockés de manière sécurisée par l'application, à la seule fin de communication avec YggTorrent, pour vous identifier sur le site.</p>
        <p>Votre nom d'utilisateur est transmis de manière chiffrée à un serveur privé qui appartient à orym.net à la seule fin de vérification et déblocage des avantages s'ils existent.</p>

    </div>
</div>
</template>