<template>
    <DynamicContainer transition="pop" :parent="$document.querySelector('#routerView')" v-for="(service, sId) in services" :key="sId">
        <div class="serviceCard">
            <img :src="service.icon" alt="">
            <div class="name">{{service.name}}</div>
            <small class="description" v-html="service.description"></small>
        </div>
    </DynamicContainer>
</template>

<script>
import services from '@/JSONData/services'
export default {
    data: () => ({
        services
    })
}
</script>

<style lang="stylus" scoped>
.serviceCard
    background rgba(0,0,0,.5)
    backdrop-filter blur(2px)
    border-radius .75rem
    padding 1rem
    width 210pt
    min-width 210pt
    height 9rem
    display flex
    flex-direction column
    align-items center
    box-shadow 0 0 0 1px rgba(0,0,0,.1)
    margin 1rem 5pt
    transition .3s!important
    position relative
    overflow hidden
    justify-content center
    transform translateY(0)
    &>*
        z-index 2
    img
        height 4.5rem
        width 4.5rem
        transition .3s
        filter contrast(0) brightness(300%) opacity(30%)
    .name
        display block
        padding 0 .5rem
        color rgba(255,255,255,.666)
        height 1.5rem
        max-height 1.5rem
    small
        text-align center
        font-size .8rem
        color white
        display block
        overflow hidden
        transition .3s
        line-height .9rem
        opacity 0
        height 0
    &::before
        content ''
        position absolute
        display block
        height 3rem
        width 3rem
        border-radius 50%
        background var(--color-card)
        transform scale(0)
        top 3rem
        left calc(50% - 1.5rem)
        opacity 0
        transition .4s
        z-index 0
    &:hover
        box-shadow 0 5px 10px 1px rgba(0,0,0,.25)
        color var(--color-text)
        transform translateY(-.75rem)
        &::before
            transform scale(12)
            opacity 1
        img
            filter contrast(100%) brightness(100%) opacity(100%)
            transform rotateY(-720deg)
        .name
        small
            color var(--color-text)
            opacity .75
            height 5rem
</style>