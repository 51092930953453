<template>
<div data-page class="skills">
    <div class="text">
        <h2>Des compétences complémentaires</h2>
        <p>
            Avec plus de {{new Date().getFullYear() - 2005}} ans d'expérience des technologies du Web et d'apprentissage en autodidacte, j'ai acquis un savoir-faire dans divers domaines: conception de pages web, administration de serveurs, création d'applications mobiles, exploitation des outils de DAO, notions d'optimisations pour le référencement...
        </p>
        <p>
            Mises à profit simultanément, ces compétences se complètent et me permettent aujourd'hui de proposer un service à 360°.
        </p>
    </div>
    <div data-card>
        <h3>Technologies</h3>
        <div class="skillItem" v-for="(service, sId) in skills" :key="sId">
            <img :src="service.icon" alt="">
            <div class="name">{{service.name}}</div>
        </div>
    </div>
    <div data-card>
        <h3>Outils</h3>
        <div class="toolItem" v-for="(tool, tId) in tools" :key="tId">
            <img :src="tool.icon" alt="">
            <div class="name">{{tool.name}}</div>
        </div>
    </div>
</div>
</template>

<script>
import skills from '@/JSONData/skills'
import tools from '@/JSONData/tools'
export default {
    data: () => ({
        skills,
        tools
    })
}
</script>

<style lang="stylus" scoped>
.skills
    display flex
    .skillItem
    .toolItem
        min-width 160pt
        display flex
        align-items center
        font-size 1.1rem
        img
            width 1.5rem
            height 1.5rem
            margin .5rem
h3
    margin-top 0

.isDesktop
    .skills
        .text
            flex 1
            margin-right 2rem
            p
                text-align justify
    [data-card]
        margin 1.5rem
.isMobile
    .skills
        flex-direction column
        p
            text-align justify
    h2
        line-height 120%
</style>